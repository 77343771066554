form{
    color: #585858;
    @media (max-width: 1280px) {
        max-width: 960px !important;
    }
    @media (max-width: 992px) {
        max-width: 760px !important;
    }
    @media (max-width: 768px){
        padding: 0 34px !important;
    }
    .form-group{
        margin-bottom: 0;
        label{
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 45px;
            @media (max-width: 1280px) {
                margin-bottom: 26px;
            }
            @media (max-width: 768px){
                display: block;
                margin-bottom: 12px;
            }

        }
        input{
            width: 330px;
            height: 60px;
            font-size: 40px;
            font-weight: 500;
            padding-left: 20px;
            border: 1px solid #ccc;
            &::placeholder{
                font-size: 20px;
            }
            @media (max-width: 1280px) {
                width: 265px !important;
                font-size: 36px;
            }
            @media (max-width: 992px) {
                width: 220px!important;
                font-size: 28px;
                padding-left: 10px;
                height: 50px;
            }
            @media (max-width: 768px) {
                font-size: 26px;
                padding-left: 6px;
                width: 100% !important;
            }
        }
        h4{
            font-size: 16px;
            font-weight: 300;
            line-height: 30px;
            @media (max-width: 1280px) {
                font-size: 15px !important;
            }
        }
        h3{
            font-size: 22px;
            line-height: 30px;
            font-weight: 700;
            margin-bottom: 22px;
            @media (max-width: 1280px) {
                font-size: 20px;
            }
        }
        p{
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;
            @media (max-width: 1280px) {
                font-size: 14px;
            }
        }
        .info-bubble{
            svg{
                width: 16px;
                margin-left: 5px;
                margin-top: -2px;
            }
        }
        .contribution-container{
            margin-bottom: 65px;
            @media (max-width: 1280px) {
                margin-bottom: 12px;
            }
            .contrubution-p-container{
                display: flex;
                justify-content: space-between;
                width: 330px;
                @media (max-width: 1280px) {
                    width: 265px;
                }
                @media (max-width: 992px) {
                    width: 220px!important;
                }
                @media (max-width: 560px){
                    width: 100% !important;
                    
                }
                p{
                    margin-bottom: 0;
                    &:last-child{
                        font-weight: 300;
                        @media (max-width: 768px){
                           
                        }
                    }
                }
                a.second-p{
                    font-weight: 300;
                    font-size: 16px;
                    color: #585858;
                    @media (max-width: 1280px){
                        font-size: 14px;
                    }
                    &:last-of-type{
                        border: 1px solid #d2d2d2;
                        padding: 0 10px;
                        border-radius: 2px;
                    }
                }
                input{
                    width: 70px !important;
                    height: 28px !important;
                    font-size: 16px !important;
                    margin-bottom: 0 !important;
                    margin-left: auto !important;
                    padding-left: 5px;
                    @media (max-width: 1280px){
                        height: 32px !important;
                        font-size: 12px;

                    }
                    &::placeholder{
                        font-size: 12px;
                        padding-left: 0;
                    }
                }

            }
        }
        
    }
    .slider{
        .slider-track{
            .slider-selection, .slider-track-high{
                background-color: #78599B;
            }
        }
    }
    .first-form-group{
        @media (max-width: 768px){
            margin: 20px 0;
        }
        &:after{
            content: "kr.";
            position: absolute;
            right: 45px;
            top: 72%;
            font-size: 22px;
            font-weight: 500;
            color: #434343;
            z-index: 6;
            @media (max-width: 1280px) {
                right: 52px;
                font-size: 20px;
                top: 60%;
            }
            @media (max-width: 992px){
                right: 23px;
                font-size: 18px;
                top: 63%;
            }
            @media (max-width: 768px){
                right: 0;
                font-size: 16px;
                top: 62%;
                right: 9%;
            }
        }
    }
    .second-form-group{
        @media (max-width: 768px){
            margin: 20px 0;
        }
        &:after{
            content: "ár";
            position: absolute;
            right: 64%;
            top: 72%;
            font-size: 22px;
            font-weight: 500;
            color: #434343;
            z-index: 6;
            @media (max-width: 1280px) {
                top: 58%;
                left: 38%;
                font-size: 20px;
            }
            @media (max-width: 992px){
                left: 50%;
                font-size: 18px;
                top: 58%;
            }
            @media (max-width: 768px){
                left: 36%;
                font-size: 16px;
                top: 62%;
            }
        }
        label{
            display: block;
        }
        input{
            width: 134px !important;
            height: 60px;
            @media (max-width: 992px){
                height: 50px;
            }
        }
    }
    .base-savings{
        color: #585858;
        margin-top: 95px;
        @media (max-width: 1280px) {
            margin-top: 40px;
        }
        @media (max-width: 992px) {
            margin-top: 30px;
        }
        @media (max-width: 560px){
            &:nth-of-type(4){
                &:after{
                    top: 19%;
                }
            }
        }

        h3{
            font-size: 22px;
            line-height: 30px;
            font-weight: 700;
            margin-bottom: 22px;
            @media (max-width: 1280px){
                font-size: 20px;
                margin-bottom: 4px;
            }
            @media (max-width: 992px) {
                font-size: 18px;
                margin-bottom: -3px;
            }
        }
        &:after{
            content: "kr.";
            position: absolute;
            right: 45px;
            top: 16%;
            font-size: 22px;
            font-weight: 500;
            color: #434343;
            z-index: 6;
            @media (max-width: 1280px) {
                right: 51px;
                font-size: 20px;
                top: 15%;
            }
            @media (max-width: 992px) {
                right: 24px;
                font-size: 18px;
                top: 13%;
            }
            @media (max-width: 768px){
                top: 13%;
            }
        }
        .simpleLabel{
            display: block;
            font-size: 16px;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 12px;
            position: relative;
            .info-bubble{
                svg{
                    width: 16px;
                    margin-left: 5px;
                    margin-top: -2px;
                }
            }
        }
        input{
            width: 330px;
            height: 60px;
            margin-bottom: 30px;
            font-size: 40px;
            font-weight: 500;
            line-height: 30px;
            padding-left: 20px;
            position: relative;
            border: 1px solid #ccc;
            &::placeholder{
                font-size: 20px;
            }
            @media (max-width: 1280px){
                width: 265px !important;
                font-size: 36px;
            }
            @media (max-width: 992px) {
                width: 220px!important;
                height: 50px;
                font-size: 28px;
                padding-left: 10px;
                &::placeholder{
                    font-size: 18px;
                }
            }
            @media (max-width: 768px){
                width: 100% !important;
            }
        }
    }
    .checkbox-col{
        h3{
            margin-left: 13px;
            @media (max-width: 768px){
                margin-left: 0;
            }
        }
        .checkbox{
            height: 36px;
            font-weight: 300;
            @media (max-width: 992px) {
                height: 30px;
                font-size: 16px;
            }
            @media (max-width: 768px){
                padding: 0;
            }
            h3{
                margin-bottom: 10px;
            }
            label{
                input{
                    top: 2px;
                    left: 0;
                    background-color: transparent;
                    width: 16px;
                    height: 16px;
                    position: relative;
                    margin-right: 12px;
                    border: 1px solid #ccc;
                    position: relative;
                    &:checked{
                        &:after {
                        content: url(../img/check.svg);
                        position: absolute;
                        width: 11px;
                        bottom: -5px;
                        right: 1px; 
                        }
                    }
                    &::placeholder{
                        font-size: 20px;
                    }

                }
            }
        }
    }

    #baseSlider, #extraEmployeeRate{
        margin-bottom: 14px;
        .slider-track{
            background: #78599B !important;
            .slider-selection{
                background-color: transparent !important;
            }
            .slider-track-high{
                background-color: #E5AF23 !important;
            }
        }
    }
    #specialReturnRateSlider, #extraReturnRateSlider, #specialPayoutYears,
    #extraPayoutYears{
        margin-top: 0;
    }
    #specialAgeOutSlider, #extraAgeOutSlider,  #baseAgeOutSlider, 
    #specialReturnRateSlider, #extraReturnRateSlider, #specialPayoutYears,
    #extraPayoutYears{
        &:before{
            position: absolute;
            bottom: 0;
            font-size: 14px;
            top: 20px;
            font-weight: 300;
        }
        &:after{
            position: absolute;
            bottom: 0;
            font-size: 14px;
            font-weight: 300;
            right: 0;
            top: 20px;
        }
    }
    #baseAgeOutSlider{
        position: relative;
        margin-top: 12px;
        &:before{
            content: '60 \E1ra';
        }
        &:after{
            content: '80 \E1ra';
        }
    }
    #specialAgeOutSlider{
        position: relative;
        &:before{
            content: '62 \E1ra';
        }
        &:after{
            content: '80 \E1ra';
        }
    }
    #extraAgeOutSlider{
        position: relative;
        &:before{
            content: '60 \E1ra';
        }
        &:after{
            content: '85 \E1ra';
        }
    }
    #specialReturnRateSlider, #extraReturnRateSlider{
        position: relative;
        &:before{
            content: '0%';
        }
        &:after{
            content: '6%';
        }
    }
    #specialPayoutYears{
        position: relative;
        &:before{
            content: '1 ár';
        }
        &:after{
            content: '20 ár';
        }
    }
    #extraPayoutYears{
        position: relative;
        &:before{
            content: '1 ár';
        }
        &:after{
            content: '20 ár';
        }
    }
    .pension-calculate-btn{
        justify-content: center;
        display: flex;
        margin: 70px;
        .birta-btn{
            border-radius: 30px;
            padding: 15px 76px;
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            color: #fff;
            background-color: #E5AF23;
        }
    }
}
canvas{
    margin: 30px 0;
}

.single-color-slider{
    h4{
        font-size: 18px !important;
    }
    .base-savings-last-h4{
        margin-bottom: 0;
        display: inline;
        margin-right: 20px;
    }
}
.slider-horizontal{
    @media (max-width: 1280px){
        width: 265px !important;
        margin-top: 13px;
    }
    @media (max-width: 992px) {
        width: 220px!important;
        margin-bottom: 38px;
    }
    @media (max-width: 768px){
        width: 100% !important;
    }
    .in{
        opacity: 1;
        font-size: 14px;
        font-weight: 700;
        .tooltip-inner{
            background-color: #e5af23;
        }
    }
}

.title-sereignarsparnaur, .title-tilgreind-sereign {
    .slider{
        .slider-track{
            .slider-selection, .slider-track-high{
                background-color: #4D7A99 !important;
            }
        }
    }
    #baseSlider, #extraEmployeeRate{
        margin-bottom: 14px;
        .slider-track{
            background: #4D7A99 !important;
            .slider-selection{
                background-color: transparent !important;
            }
            .slider-track-high{
                background-color: #E5AF23 !important;
            }
        }
    }
}


/* results */
.results{
    @media (max-width: 768px){
        padding: 0;
    }
    h4{
        font-size: 18px;
    }
    .legal-disclaimer{
        margin-top: 12px;
        a{
            border-bottom: 2px solid #e5af23;
            font-size: 15px;
            font-weight: 300;
        }
    }
    .legal-disclaimer-small{
        font-style: italic;
        color: #434343;
        @media (max-width: 992px){
            font-size: 15px;
            line-height: 22px;
        }
        .legal-disclaimer{
            a{
                border-bottom: 1px solid #e5af23;
            }
        }
    }
}
.modal{
    .modal-dialog{
        .modal-content{
            border: none;
            box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);
            padding: 20px 20px 0 20px;
            .modal-header{
                padding: 2rem 1rem 1rem 1rem;
                button{
                    font-size: 28px;
                }
            }
            .modal-body{
                p{
                    font-size: 15px;
                    line-height: 24px;
                    a{
                        font-size: 16px;
                        font-weight: 500;
                        border-bottom: 2px solid #e5af23;
                        line-height: 38px;
                    }
                }
            }
        }
    }
}
.table{
    flex-direction: column;
    margin: 0px 0 50px 0;
    font-size: 16px;
    @media (max-width: 560px){
        font-size: 15px;
        line-height: 18px;
    }
    .results-table-row{
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        height: 50px;
        border-bottom: 1px solid #f2f2f2;
        @media (max-width: 560px){
            padding: 6px 0;
            height: 64px;
        }
        .table-head{
            display: flex;
            align-items: center;
            @media (max-width: 560px){
                width: 50%;
                word-wrap: break-word;
            }
        }
        .text-right{
            display: flex;
            align-items: center;
            @media (max-width: 560px){
                width: 50%;
                justify-content: flex-end;
            }
        }
    }
}
.legal-disclaimer-container{
    padding: 0;
    .legal-disclaimer-body{
        .rich-text{
            p{
                &:last-child{
                    margin: 30px 0;
                }
            }
            a{
                border-radius: 30px;
                padding: 12px 15px;
                font-size: 16px;
                font-weight: 500;
                color: #fff;
                background-color: #E5AF23;
            }
        }
    }
}

.results-h3{
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    color: #252525;
    margin: 30px 0 10px 0;
    display: inline-block;
}
.results-h4{
    margin-top: 40px;
    h4{
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 18px !important;
        color: #252525;
        span{
            font-size: 12px;
            margin-top: 8px;
            font-style: italic;
            font-weight: 300;
        }
    }

}



/*copylinks*/

.copy-link-container{
    display: inline-block;
    .copy-links{
        background-image: url(../img/copy-link-icon.svg);
        background-repeat: no-repeat;
        margin-left: 14px;
        width: 22px;
        button{
            height: 24px;
            padding-top: 2px;

        }
    }
}
